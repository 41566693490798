import React from "react";
import { graphql, useStaticQuery } from "gatsby"

import { Layout, SEO } from '../../components/structure';
import {
  Hero,
  Content,
  ExecutiveBio,
  Button,
  CallToAction
} from "../../components/blocks";

import styled from "styled-components";
import { mq, brand, font } from "../../styles"

import english from "../../../content/translations/en-CA/about.json"
import french from "../../../content/translations/fr-CA/about.json"



const ExecLeadershipTeam = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-ELT.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIconBlue: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      execKenTile: file(base:{eq: "KenHarris.jpg"}){
        ...BioTileImage
      }
      execKenPopup: file(base:{eq: "KenHarris.jpg"}){
        ...BioPopupImage
      }
      execBrettTile: file(base:{eq: "BrettM.jpg"}){
        ...BioTileImage
      }
      execBrettPopup: file(base:{eq: "BrettM.jpg"}){
        ...BioPopupImage
      }
      execDanielleTile: file(base:{eq: "DanielleB.jpg"}){
        ...BioTileImage
      }
      execDaniellePopup: file(base:{eq: "DanielleB.jpg"}){
        ...BioPopupImage
      }
      execGeorgeTile: file(base:{eq: "GeorgeY.jpg"}){
        ...BioTileImage
      }
      execGeorgePopup: file(base:{eq: "GeorgeY.jpg"}){
        ...BioPopupImage
      }
      execSarahTile: file(base:{eq: "SarahB.jpg"}){
        ...BioTileImage
      }
      execSarahPopup: file(base:{eq: "SarahB.jpg"}){
        ...BioPopupImage
      }
      execErickTile: file(base:{eq: "ErickG.jpg"}){
        ...BioTileImage
      }
      execErickPopup: file(base:{eq: "ErickG.jpg"}){
        ...BioPopupImage
      }
      execJayTile: file(base:{eq: "JayM.jpg"}){
        ...BioTileImage
      }
      execJayPopup: file(base:{eq: "JayM.jpg"}){
        ...BioPopupImage
      }
      execJoseeTile: file(base:{eq: "JoseeK.jpg"}){
        ...BioTileImage
      }
      execJoseePopup: file(base:{eq: "JoseeK.jpg"}){
        ...BioPopupImage
      }
      
    }
  `);

  const ExecutiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 35px 70px;
    width: 95%;
    max-width: 1400px;
    margin: auto;
    background: #fff;
    padding: 100px 0;
    @media screen and ${mq.maxMd} {
      display: block;
      
    }
  `;

  const ExecutiveIntro = styled.div`
  
  ${ font.imports.sherika }

    width: 95%;
    max-width: 1400px;
    margin: 100px auto;
    @media screen and ${mq.maxMd} {
      text-align: center;
    }

    h4 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 48px;
      color: ${brand.colors.pg_blue};
      
    }

    h3 {
      font-family: ${font.family.sherika};
      font-weight:600;
      font-size: 32px;
      color: #fff;
    }
  `;

  return(
    <Layout>
    <SEO title={"Executive Leadership Team | Plusgrade"} 
          description={'Meet our Executive leadership team - the diverse group of people leading the way to make Plusgrade the global ancillary revenue powerhouse.'}
          keywords={'leadership, ceo, executives'}
          />
    <Hero
      title={"Executive\nLeadership Team"}
      subtitle={lang.hero.subtitle}
      image={ data['heroImg'] }
      imageStyle={{objectPosition: "center bottom"}}
      fullBG
      />
  <Content contained>
    <ExecutiveIntro>
      <h4>Meet our Executive Leadership Team</h4>
      <h3>
        When it comes to achieving our mission to be the global ancillary revenue powerhouse, this team is leading the way. Learn about the diverse experience they bring to Plusgrade.
      </h3>
    </ExecutiveIntro>
  </Content>
  
  <Content whiteBG>
    <ExecutiveContainer>
      <ExecutiveBio 
        name={"Ken Harris"}
        title={"Founder & CEO"}
        blurb={"Ken Harris is the Founder & Chief Executive Officer of Plusgrade, a leading software company in the global travel industry and one of Canada’s largest private technology companies. Under Ken’s leadership, Plusgrade has delivered billions of dollars of new revenue opportunity to over 200 travel suppliers across 60 countries and was honoured with the prestigious Deloitte Technology Leadership award as the global leader in its sector. In 2020, Ken was named to Canada’s Top 40 Under 40, an annual recognition of exceptional achievements"}
        profileIcon = {data['execKenTile']}
        profilePopup = {data['execKenPopup']}
        linkedin={'https://www.linkedin.com/in/kenharris/'}
      />

      <ExecutiveBio 
        name={"Jay Malowney"}
        title={"President, Points"}
        blurb={"Jay oversees all aspects of Points Business Unit strategy, performance, growth, and partnerships. Previously, he was Chief Commercial Officer at Points, where he managed commercial growth, performance, relationship management, marketing, growth teams, and strategic partnerships. He brings his vast knowledge and experience from the loyalty sector to the team, ensuring that the organization continues to drive innovation and progress. Prior to joining Points, Jay held senior roles at LoyaltyOne, including leading their successful expansion into the Brazilian marketplace with the Dotz coalition loyalty program."}
        profileIcon = {data['execJayTile']}
        profilePopup = {data['execJayPopup']}
        linkedin={'https://www.linkedin.com/in/jay-malowney-3302693/'}
      />
      <ExecutiveBio 
        name={"Brett Mellon"}
        title={"President, Solutions"}
        blurb={"Brett is an accomplished engineering professional with over 30 years of experience in 5 different industries, including a focus on tech in operations roles for the last 10+ years. He is renowned for his problem-solving acumen and broad understanding of operations, having worked at every level of management. Brett has also devoted over 15 years to the automotive sector, demonstrating his ability to lead and inspire teams while also providing outstanding service to others"}
        profileIcon = {data['execBrettTile']}
        profilePopup = {data['execBrettPopup']}
        linkedin={'https://www.linkedin.com/in/brett-mellon-99418615/'}
      />
      <ExecutiveBio 
        name={"Sarah Bettencourt"}
        title={"Chief People Officer"}
        blurb={"Sarah is responsible for overseeing all aspects of people and culture for the organization, including performance management, succession planning, business insights, and strategy. Sarah holds a Masters in Organizational Effectiveness & Design and has received a number of awards (Female Business Leader of the Year and Female Executive Leader Award in 2019)."}
        profileIcon = {data['execSarahTile']}
        profilePopup = {data['execSarahPopup']}
        linkedin={'https://www.linkedin.com/in/sarah-bettencourt-mhrm-2bb5754/'}
      />
      <ExecutiveBio 
        name={"Danielle Brown"}
        title={"Chief Marketing Officer"}
        blurb={"Danielle is responsible for overseeing Data Science & Analytics, Global Brand & Content, and Partner, Product & Performance Marketing. With over 20 years of experience in the field, she has established successful marketing and business operations teams for national and global organizations. Danielle is an expert in change management and a trained negotiator and facilitator, skilled in building consensus and driving businesses forward. Her diverse career spans the fields of entertainment, wireless, loyalty, retail, e-commerce, and tech, across both B2B and B2C markets."}
        profileIcon = {data['execDanielleTile']}
        profilePopup = {data['execDaniellePopup']}
        linkedin={'https://www.linkedin.com/in/danielle-brown-7aaa3427/'}
      />
      <ExecutiveBio 
        name={"Erick Georgiou"}
        title={"Chief Financial Officer"}
        blurb={"Erick is responsible for the overall financial health of the company. Erick was previously the CFO of Points (now a Plusgrade company) and was an integral part of the Company's growth as a dual listed public company since joining in 2008. During his tenure at Points, he led a broad range of Finance functions, including business planning and analysis, accounting, investor relations, treasury and tax, payments and risk management. "}
        profileIcon = {data['execErickTile']}
        profilePopup = {data['execErickPopup']}
        linkedin={'https://www.linkedin.com/in/erick-georgiou-cpa-ca-8591609/'}
      />

      <ExecutiveBio 
        name={"George Yao"}
        title={"Chief Strategy Officer"}
        blurb={"George brings over 17 years of experience as a strategic and financial advisor to the company. During his time at Morgan Stanley and TD Securities, George served as Managing Director and led Canadian technology and financial sponsors investment banking coverage. He has a successful track record of leading high profile and transformational IPO and M&A transactions. George holds an MBA from the University of Toronto, and an MSc in Computer Science from Western University, graduating with high honors for both."}
        profileIcon = {data['execGeorgeTile']}
        profilePopup = {data['execGeorgePopup']}
        linkedin={'https://www.linkedin.com/in/georgeyao/'}
      />

      <div style={{visibility: "hiddn", width: "250px"}}>

      </div>

    </ExecutiveContainer>

    <CallToAction responsiveButton
      title={'Join our team'}
      subtitle={'We\’re always looking for driven, creative people to join our team.'}
      > 
      <Button destination={'/careers/'} label={'See open roles'} icon={data['rightArrowIconBlue']} white blueText pill />
    </CallToAction>

  </Content>
  </Layout>
  )
};


export default ExecLeadershipTeam;